import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, filter } from 'rxjs';
import { AppRoutingModule } from '../app-routing.module';
import { AppState } from '../app.state';
import { EntityDeltaVM } from '../shared/models/entity-delta.vm.model';
import { DateFormatEnum } from '../shared/pipes/custom-date.pipe';
import { SharedModule } from '../shared/shared.module';
import { DriverChangeComponent } from './driver-change/driver-change.component';
import { PartnerChangeComponent } from './partner-change/partner-change.component';
import { EntityChangesActions, EntityChangesSelector } from './store';
import { Context } from './store/entity-logs.actions';
import { VehicleChangeComponent } from './vehicle-change/vehicle-change.component';

@Component({
  selector: 'app-entity-logs',
  templateUrl: './entity-logs.component.html',
  styleUrls: ['./entity-logs.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    AppRoutingModule,
    MatExpansionModule,
    MatDividerModule,
    NgOptimizedImage,
    MatCardModule,
    MatPaginatorModule,
    SharedModule,
    MatProgressBarModule,
    MatButton,
    MatIconButton,
    PartnerChangeComponent,
    DriverChangeComponent,
    VehicleChangeComponent,
  ],
})
export class EntityLogsComponent implements OnInit {
  changes$: Observable<EntityDeltaVM[]>;
  page$: Observable<PageEvent>;
  isLoading$: Observable<boolean>;
  context$: Observable<Context>;
  destroyRef = inject(DestroyRef);

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store.select(EntityChangesSelector.selectLoading);
    this.context$ = this.store.select(EntityChangesSelector.selectContext);
    this.changes$ = this.store.select(EntityChangesSelector.selectEntityChangeVMs);
    this.page$ = this.store.select(EntityChangesSelector.selectEntityChangePage);
    this.watchRouteChanges();
  }

  handlePage(event: PageEvent): void {
    this.store.dispatch(EntityChangesActions.entityChangeLogPageChanged({ page: event }));
  }

  onCloseButtonClick(): void {
    this.store.dispatch(EntityChangesActions.entityChangeLogClosed());
  }

  private watchRouteChanges(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.store.dispatch(EntityChangesActions.entityChangeLogClosed());
      });
  }

  protected readonly DateFormatEnum = DateFormatEnum;
}
