<mat-card>
  <mat-card-title>
    <button mat-icon-button (click)="onCloseButtonClick()">
      <mat-icon>close</mat-icon>
    </button>
    Change history
  </mat-card-title>
  <mat-card-content>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading$ | async"></mat-progress-bar>

    <table class="log-table">
      <tbody>
        <tr class="border-bottom" *ngFor="let change of changes$ | async">
          <td>
            <mat-icon class="log-icon">{{ change.icon.toString() }}</mat-icon>
          </td>
          <td>
            <div class="setting-change-line">
              @if (change.entityName === 'vehicle-service') {
                <app-vehicle-change [change]="change"></app-vehicle-change>
              }
              @if (
                change.entityName === 'partner-company' ||
                change.entityName === 'partner-vehicleinsurance' ||
                change.entityName === 'partner-liabilityinsurance' ||
                change.entityName === 'partner-occupationalinsurance' ||
                change.entityName === 'partner-transportlicense' ||
                change.entityName === 'partner-contract'
              ) {
                <app-partner-change [change]="change"></app-partner-change>
              }
              @if (change.entityName === 'driver-service') {
                <app-driver-change [change]="change"></app-driver-change>
              }
            </div>
            <div class="setting-metadata">
              <span class="log-user">{{ change.userName }}</span>
              <span class="log-date">{{ change.date | customdate }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <mat-paginator
      *ngIf="page$ | async as page"
      [length]="page.length"
      [pageSize]="page.pageSize"
      [pageIndex]="page.pageIndex"
      (page)="handlePage($event)"></mat-paginator>
  </mat-card-content>
</mat-card>
